import { Get, Post, Put, Delete } from './network.service';

export const generateInitBDDDatabase = () => {
  return Post('init-bdd/generate', {});
};

export const scheduleInitBDDDatabase = (createProcess) => {
  return Post('init-bdd/schedules', createProcess);
};

export const updateScheduleInitBDDDatabase = (id, process) => {
  return Put(`init-bdd/schedules/${id}`, process);
};

export const deleteScheduleInitBDDDatabase = (id) => {
  return Delete(`init-bdd/schedules/${id}`);
};

export const transferInitBDDDatabase = (envTarget) => {
  return Post('init-bdd/transfer', { envTarget });
};

export const listScheduledInitBDDDatabase = () => {
  return Get('init-bdd/schedules');
};

export const listBackupInitBDDDatabase = () => {
  return Get('init-bdd/backups');
};

export const restoreBackupInitBDDDatabase = (backupId) => {
  return Put(`init-bdd/backups/${backupId}`);
};

export const getInitBDDStatus = () => {
  return Get('init-bdd/status');
};

export const getInitBDDConfig = () => {
  return Get('init-bdd/config');
};
