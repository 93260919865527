import { useContext } from 'react';
import AppContext from 'src/contexts/AppContext';
import * as Yup from 'yup';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Box, Button, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { DateTime } from 'luxon';
import { DateTimePicker } from '@mui/x-date-pickers';
import { scheduleInitBDDDatabase, updateScheduleInitBDDDatabase } from 'src/services/init-bdd.service';

const BDDGenerationScheduleForm = ({ initBDDProcess, onClose }) => {
  // Get AppContext
  const contextProps = useContext(AppContext);

  const { setMessage } = contextProps;

  return (
    <Formik
      initialValues={{
        date: initBDDProcess ? DateTime.fromISO(initBDDProcess.date) : undefined
      }}
      validationSchema={Yup.object().shape({
        date: Yup.date().required('La date de planification est obligatoire')
      })}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);

        const { date } = values;

        if (initBDDProcess) {
          updateScheduleInitBDDDatabase(initBDDProcess.id, {
            date
          })
            .then(() => {
              setSubmitting(false);
              onClose(true);
            })
            .catch((e) => {
              if (axios.isAxiosError(e)) {
                setMessage({
                  type: 'error',
                  text: `Erreur lors de la mise à jour de la planification : ${e.response.data.message}`
                });
              } else {
                setMessage({ type: 'error', text: 'Erreur lors de la mise à jour de la planification' });
              }
            });
        } else {
          scheduleInitBDDDatabase({ scheduledAt: date })
            .then(() => {
              setSubmitting(false);
              onClose(true);
            })
            .catch((e) => {
              if (axios.isAxiosError(e)) {
                setMessage({
                  type: 'error',
                  text: `Erreur lors de la création de la planification : ${e.response.data.message}`
                });
              } else {
                setMessage({ type: 'error', text: 'Erreur lors de la création de la planification' });
              }
            });
        }
      }}
    >
      {({ errors, handleBlur, handleSubmit, touched, values, isValid, setFieldValue }) => {
        return (
          <form
            autoComplete="off"
            noValidate
            style={{
              width: '100%'
            }}
          >
            <Card sx={{ minWidth: 500 }}>
              <CardHeader
                subheader={initBDDProcess ? "Modification d'une planification" : 'Créer une nouveau planification'}
                title="Planification"
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <DateTimePicker
                      error={Boolean(touched.date && errors.date)}
                      fullWidth
                      label="Date de planification"
                      format="dd/MM/yyyy HH:mm"
                      margin="normal"
                      name="date"
                      onBlur={handleBlur}
                      onChange={(ev) => {
                        if (ev !== null) {
                          setFieldValue('date', ev);
                        }
                      }}
                      type="text"
                      value={values.startDate}
                      timezone="system"
                      variant="outlined"
                    />
                    {touched.startDate && errors.startDate && (
                      <p
                        style={{
                          fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                          fontWeight: 400,
                          fontSize: '0.75rem',
                          lineHeight: 1.66,
                          letterSpacing: '0.03333em',
                          textAlign: 'left',
                          marginTop: '3px',
                          marginRight: '14px',
                          marginBottom: 0,
                          marginLeft: '14px',
                          color: '#d32f2f'
                        }}
                      >
                        {errors.startDate}
                      </p>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2
                }}
              >
                <Button style={{ marginRight: '10px' }} color="secondary" variant="contained" onClick={onClose}>
                  Annuler
                </Button>
                <Button color="primary" variant="contained" disabled={!isValid} onClick={handleSubmit}>
                  {initBDDProcess ? 'Modifier' : 'Ajouter'}
                </Button>
              </Box>
            </Card>
          </form>
        );
      }}
    </Formik>
  );
};

BDDGenerationScheduleForm.propTypes = {
  initBDDProcess: PropTypes.object,
  onClose: PropTypes.func
};

export default BDDGenerationScheduleForm;
