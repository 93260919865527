import { useState } from 'react';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  TextField
} from '@mui/material';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

const BDDTransferForm = ({ onGoing, handleTransfer, transferDestinations }) => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedEnvTarget, setSelectedEnvTarget] = useState();

  /**
   * Handle confirmation dialog closing event
   */
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const getDestinationLabel = () => {
    const findDestinations = transferDestinations.find((td) => td.value === selectedEnvTarget);

    if (!findDestinations) {
      return '<VALEUR INCONNUE>';
    }

    return findDestinations.label;
  };

  return (
    <>
      <Formik
        initialValues={{
          envTarget: ''
        }}
        validationSchema={Yup.object().shape({
          envTarget: Yup.string().required('La destination est obligatoire')
        })}
        onSubmit={(values) => {
          setSelectedEnvTarget(values.envTarget);

          handleOpenConfirm();
        }}
        enableReinitialize
      >
        {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
          <form autoComplete="off">
            <Card>
              <CardHeader subheader="Transfert de la base de données mobiles" title="Init BDD" />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    {transferDestinations && (
                      <TextField
                        error={Boolean(touched.envTarget && errors.envTarget)}
                        helperText={touched.envTarget && errors.envTarget}
                        fullWidth
                        label="Destination"
                        name="envTarget"
                        required
                        select
                        SelectProps={{ native: true }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.envTarget}
                        variant="outlined"
                      >
                        <option key="null" value="">
                          &nbsp;
                        </option>
                        {transferDestinations.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    )}
                  </Grid>
                </Grid>
              </CardContent>

              <Divider />

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2
                }}
              >
                <Button color="primary" variant="contained" onClick={handleSubmit} disabled={onGoing}>
                  Transférer
                </Button>
              </Box>
            </Card>
          </form>
        )}
      </Formik>

      <Dialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle id="alert-dialog-title">Attention</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Etes-vous sur de vouloir mettre à jour l&apos;environnement de {getDestinationLabel()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirm}>Annuler</Button>
          <Button
            onClick={(event) => {
              handleCloseConfirm(event);
              handleTransfer(selectedEnvTarget);
            }}
            autoFocus
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

BDDTransferForm.propTypes = {
  onGoing: PropTypes.bool,
  handleTransfer: PropTypes.func,
  transferDestinations: PropTypes.array
};

export default BDDTransferForm;
