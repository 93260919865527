import {
  Box,
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import PropTypes from 'prop-types';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useState } from 'react';
import { DateTime } from 'luxon';

const BDDGenerationForm = ({ onGoing, handleScheduleOpenDialog, scheduledProcesses, handleGenerate, handleDeleteSchedule }) => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [currentProcess, setCurrentProcess] = useState();

  /**
   * Handle confirmation dialog closing event
   */
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenConfirm = (process) => {
    setCurrentProcess(process);
    setOpenConfirm(true);
  };

  const formatCurrentProcessDateTime = () => {
    if (!currentProcess) {
      return '';
    }

    const dateTime = DateTime.fromFormat(currentProcess.scheduledAt, 'dd/MM/yyyy HH:mm:ss');

    return `${dateTime.toFormat('dd/MM/yyyy')} à ${dateTime.toFormat('HH:mm')}`;
  };

  return (
    <>
      <Card>
        <CardHeader subheader="Génération des bases de données mobiles" title="Init BDD" />

        <Divider />

        <Grid item lg={12} md={12} xs={12}>
          {scheduledProcesses && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="tablecell-green">Date planifiée</TableCell>
                  <TableCell className="tablecell-green">Planifiée par</TableCell>
                  <TableCell className="tablecell-green" style={{ width: '50px' }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody md={12} xs={12}>
                {scheduledProcesses.map((process) => {
                  return (
                    <TableRow key={process.id}>
                      <TableCell>{process.scheduledAt}</TableCell>
                      <TableCell>{process.user}</TableCell>
                      <TableCell>
                        <Tooltip title="Supprimer cette planification">
                          <Button startIcon={<DeleteOutlineIcon />} color="primary" onClick={() => handleOpenConfirm(process)} />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </Grid>

        <Grid item lg={12} md={12} xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '10px',
              p: 2
            }}
          >
            <Button color="primary" variant="contained" onClick={handleScheduleOpenDialog}>
              Planifier
            </Button>
            <Button color="primary" variant="contained" onClick={handleGenerate} disabled={onGoing}>
              Générer
            </Button>
          </Box>
        </Grid>
      </Card>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      />

      <Dialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle id="alert-dialog-title">Attention</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Etes-vous sur de vouloir supprimer la planification du {formatCurrentProcessDateTime()} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirm}>Annuler</Button>
          <Button
            onClick={(event) => {
              handleCloseConfirm(event);
              handleDeleteSchedule(currentProcess.id);
            }}
            autoFocus
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

BDDGenerationForm.propTypes = {
  onGoing: PropTypes.bool,
  handleScheduleOpenDialog: PropTypes.func,
  scheduledProcesses: PropTypes.array,
  handleGenerate: PropTypes.func,
  handleDeleteSchedule: PropTypes.func
};

export default BDDGenerationForm;
